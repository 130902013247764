/* ##### Breadcumb Area ##### */

.breadcumb-area {
  position: relative;
  z-index: 1;
  height: 400px !important;
  background: url(../../assets/img/sub_top_img.jpg) no-repeat center;
  /* background: url(../../assets/img/bg-img/bread.png) no-repeat center; */
  background-size: cover;
}

.breadcumb-content {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  /* background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.5),
    rgba(52, 31, 151, 0.5)
  ); */
}

.breadcumb--con {
  padding-top: 90px;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent !important;
  border-radius: 0;
}

.breadcumb--con .title {
  font-size: 42px;
  margin-bottom: 15px;
  margin-left: 0;
}

.breadcrumb-item,
.breadcrumb-item.active,
.breadcrumb-item > a {
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-link {
  color: #fff;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin: 0 2px;
}
