/* ##### 11.0 Price Table Area CSS ##### */
.pricing-item {
    border-radius: 10px;
    background-color: #0a1026;
    position: relative;
    border: solid 1px rgba(255, 255, 255, 0.25);
    text-align: left;
    padding: 10px 10px 20px;
    margin: 0 0 30px;
}

.pricing-item:before {
    content: "";
    position: absolute;
    width: 102.6%;
    height: 101.3%;
    top: -3px;
    left: -3px;
    border-radius: 15px;
    z-index: -1;
    opacity: 0.4;
    background-image: linear-gradient(to top, #321884, #e51052), linear-gradient(to top, #321884, #e51052);
}

.pricing-item.v2 {
    padding: 10px 20px;
    margin-bottom: 20px;
    color: #fff;
    text-align: left;
    cursor: pointer;
}

.pricing-item.v2:before {
    width: 101.6%;
    height: 111%;
    border-radius: 10px;
}

.wal-icon {
    margin-right: 10px
}

.pricing-item h4 {
    font-size: 18px;
    margin-top: 50px;
    line-height: 1.43;
    color: #fff;
}

.owner-info {
    display: flex;
    align-items: center;
    background: #03091f;
    border: 1px solid #969494;
    position: absolute;
    top: 225px;
    left: 30px;
    padding: 7px 10px;
    border-radius: 30px;
}

.pricing-item h3 {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 10px;
}

.pricing-item .pricing {
    font-size: 15px;
    line-height: 18px;
    margin: 0 0 20px;
    color: #fff
}

.pricing-item span {
    font-size: 14px;
    display: inline-block;
    color: #00e0c4;
    margin: 0 0 7px;
}

.pricing-item label {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    width: 100%;
    color: #fff;
    font-weight: normal;
    letter-spacing: 0.5px;
    background-size: 90%;
    background-image: linear-gradient(117deg, #724eff, #5187ff);
    background-repeat: no-repeat;
    background-position: left;
    /*background-image: url(../img/svg/discount-bg-1.svg);*/
}

.white-popup {
    position: relative;
    background: rgb(11 17 38);
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    padding: 40px 20px;
    width: auto;
    max-width: 500px;
    margin: 20px auto;
}

.portfolio-menu button.active {
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.portfolio-menu button {
    line-height: 1;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    text-transform: uppercase;
    padding: 8px 15px 5px;
    border-radius: 30px;
}

.social-list {
    display: inline-block;
    margin-bottom: 0;
    height: 40px;
    position: relative;
    z-index: 2;
}

.social-list li {
    float: left;
    padding: 0 14px
}

.social-list li a {
    font-size: 34px;
    color: #fff;
    opacity: .8
}

.social-list li a:hover {
    opacity: 1
}

.subscribe .buy-tokens i {
    margin-right: 10px
}

.subscribe .dream-btn {
    position: absolute;
    top: -2px;
    right: -10px;
    min-width: 48px;
    height: 48px;
    padding: 0;
    border-color: #fff;
}

.button {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: inline-block;
    padding: 14px 40px;
    font-size: 16px;
    z-index: 2;
    line-height: 1.25;
    color: #25cbd3;
    background: #fff;
    border: 0 solid;
    border-radius: 40px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    transition: 250ms all ease-in-out;
    box-shadow: 0 3px 21px 4px rgba(41, 41, 58, 0.2);
}

.button:hover {
    background: #25cbd3;
    color: #fff
}

.button i {
    margin-right: 10px
}
