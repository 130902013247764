/* ##### 3.0 Wellcome Area CSS ##### */
.hero-section {
  position: relative;
  min-height: 700px;
  /*background: url('../img/bg-img/header2.jpg') no-repeat bottom center;*/
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section.moving {
  overflow: hidden;
  /* background: url(../../assets/img/bg-img/header-1.jpg) no-repeat center top;  */
  background: url(../../assets/img/main_visual.jpg) no-repeat center top;
  background-size: cover;
}

@media (min-width: 1200px) {
  .hero-section img {
    max-width: 170%;
    margin-left: -100px;
    margin-top: 60px;
  }
  .hero-section .curved {
    max-width: 100%;
    margin-top: 30px;
  }
}

.moving-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500em;
  height: 70%;
  opacity: 0.1;
  visibility: inherit;
  background: transparent url(../../assets/img/core-img/crypto.png) repeat-x
    scroll 0 100%;
  -webkit-animation: cloudwash 60s linear infinite;
  -moz-animation: cloudwash 60s linear infinite;
  animation: cloudwash 60s linear infinite;
}
@media (max-width: 991px) {
  .hero-section.moving {
    /* background-position: left top; */
  }
}
@-webkit-keyframes cloudwash {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes cloudwash {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@media (max-width: 380px) {
  .btn-explore,
  .btn-Collect {
    margin-bottom: 1rem;
  }
}

.gradient-text {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #846ff4 0%, #f17674 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gradient-text.blue {
  background-image: linear-gradient(35deg, #17ead9 0%, #6078ea 100%);
}

.welcome_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 900px !important;
}

.hero-content {
  width: 100%;
  height: 100%;
  background: rgba(72, 52, 212, 0.95);
  background: -webkit-linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: -webkit-linear-gradient(
    left,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
}

.welcome-content {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}

.promo-section {
  margin-bottom: 30px;
}
.special-head {
  color: #28cc8b;
  padding-left: 40px;
  font-size: 18px;
  position: relative;
}
.special-head:before {
  content: '';
  background: #3964f9;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
}

.integration-link {
  padding: 8px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  letter-spacing: 0.58px;
  font-size: 14px;
  color: #333;
  line-height: 24px;
  color: #d5d5ea;
}

.integration-link:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
  border-radius: 100px;
}
.integration-icon {
  margin: 0 5px 0 0;
}
.integration-icon .badge {
  background: #14cab1;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 100px;
  padding: 6px 13px;
}
.integration-text {
  margin: 0 7px 0 0;
  color: #7e7b7b;
}
.integration-link > * {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
}

.has-shadow {
  -webkit-box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.2);
  box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.2);
  border-radius: 10px;
}

@media (max-width: 767px) {
  .welcome-content {
    padding-right: 15px;
  }
}

.welcome-content h2 {
  font-size: 34px;
  font-weight: 200;
  /* font-weight: 500; */
  /* margin-bottom: 20px; */
}
.welcome-content h1 {
  font-size: 94px;
  font-weight: 500;
  margin-bottom: 20px;
}

.welcome-content p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .welcome-content h2 {
    font-size: 20px;
    font-weight: 200;
  }
  .welcome-content h1 {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
